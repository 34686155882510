import {
  SALESFORCE_CHAT_ERROR,
  SALESFORCE_CHAT_OPENED,
  SALESFORCE_CHAT_OPENED_REQUEST,
  SalesforceChatCustomEvents,
} from '@features/SalesforceChat/customEvents';
import {
  INTERCOM_ERROR,
  INTERCOM_OPENED,
  INTERCOM_OPENED_REQUEST,
  IntercomCustomEvents,
} from 'utils/fixIntercomLunch/customEvents';
import { TrackingRule } from 'utils/tracking/aspectTracking';
import { TrackingService } from 'utils/tracking/interfaces';

type ObjectWithTrackMethod = {
  track: TrackingService['track'];
};
type Props = {
  trackingService: ObjectWithTrackMethod;
};
export const getSupportChatsTrackingRules = ({
  trackingService,
}: Props): TrackingRule<IntercomCustomEvents | SalesforceChatCustomEvents>[] => [
  (is) => void (is('document', INTERCOM_ERROR) && trackingService.track('Intercom not opened')),
  (is) => void (is('document', INTERCOM_OPENED_REQUEST) && trackingService.track('Intercom open request')),
  (is) => void (is('document', INTERCOM_OPENED) && trackingService.track('Intercom opened')),

  (is) => void (is('document', SALESFORCE_CHAT_ERROR) && trackingService.track('Salesforce chat not opened')),
  (is) =>
    void (is('document', SALESFORCE_CHAT_OPENED_REQUEST) && trackingService.track('Salesforce chat open request')),
  (is) => void (is('document', SALESFORCE_CHAT_OPENED) && trackingService.track('Salesforce chat opened')),
];
