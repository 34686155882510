import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ENV from 'env';
import { ACTIVE_FLAGS, useFlag } from 'feature-flags';
import { getCurrentUser } from 'selectors/getCurrentUser';
import { getCurrentVenue } from 'selectors/getCurrentVenue';
import { Logger } from 'utils/logger';
import { withCatchToError } from 'utils/sentryUtils';
import { ValidURLString } from 'utils/tests/mockServerTools';
import { loadScript } from 'utils/loadScript';

import { SalesforceChatPreChatFields } from './types';
import './salesforceChatOverrides.css';
import { waitForSalesforceInitialized, assertEnvDefined } from './utils';
import { openSalesforce } from '@features/SalesforceChat/openSalesforce';
import { addToErrorContext } from '@features/SalesforceChat/errorHandling';

const logger = new Logger('SalesforceChat');

const initSalesforceChat = withCatchToError(
  'SalesforceChat:initSalesforceChat',
  async (prechatFields: SalesforceChatPreChatFields) => {
    logger.debug('initEmbeddedMessaging: Waiting for Salesforce API to be available...');
    await waitForSalesforceInitialized();
    logger.debug('initEmbeddedMessaging: API Ready!', window.embeddedservice_bootstrap);
    logger.debug('initEmbeddedMessaging: Starting...', window.embeddedservice_bootstrap);
    const salesforceENV = assertEnvDefined(ENV);

    window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'
    window.addEventListener('onEmbeddedMessagingReady', () => {
      logger.debug('onEmbeddedMessagingReadyListner: Setting Prechat Fields', prechatFields);
      window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields(prechatFields);
    });

    const initArgs: Parameters<typeof window.embeddedservice_bootstrap.init> = [
      salesforceENV.UALA_SALESFORCE_ORG_ID,
      salesforceENV.UALA_SALESFORCE_ESW_CONFIG_DEV_NAME,
      <ValidURLString>salesforceENV.UALA_SALESFORCE_SITE_URL,
      {
        scrt2URL: salesforceENV.UALA_SALESFORCE_SCRT2URL,
        hideChatButtonOnLoad: true,
      },
    ];
    addToErrorContext('initArgs', initArgs);

    window.embeddedservice_bootstrap.init(...initArgs);
    logger.debug('initEmbeddedMessaging: window.embeddedservice_bootstrap.init called...', initArgs);
  }
);

const salesforceChatLuncherSelector = '.salesforce-chat-support';

export const useSalesforceChat = withCatchToError('SalesforceChat:useSalesforceChat', () => {
  const salesforceENV = assertEnvDefined(ENV);
  const salesforceChatDisabled = !useFlag(ACTIVE_FLAGS.FE_CHAT_SALESFORCE);

  const user = useSelector(getCurrentUser);
  const venue = useSelector(getCurrentVenue);

  useEffect(() => {
    if (salesforceChatDisabled) return;

    window.document.addEventListener('click', (event) => {
      if ((<HTMLElement>event.target).closest(salesforceChatLuncherSelector)) return openSalesforce();
    });
  }, [salesforceChatDisabled]);

  useEffect(() => {
    if (salesforceChatDisabled) return;

    logger.debug('useSalesforceChat: venue, user', venue, user);
    if (!venue || !user) return;

    const sfUserData: SalesforceChatPreChatFields = {
      externalUserFullName: user.full_name,
      externalUserId: Number(user.id),
      externalVenueId: Number(venue.id),
      externalLanguage: String(venue.locale),
      externalSource: 'TWPRO',
      externalUserEmail: user.email,
      externalCountry: String(venue.country?.code).toUpperCase(),
      profileAccessToken: 'xxx',
    };

    logger.debug('%cuseSalesforceChat: loading script', 'font-size: 20px; color: skyblue;', sfUserData);
    addToErrorContext('sfUserData', sfUserData);
    addToErrorContext('UALA_SALESFORCE_BOOTSTRAP_CODE_URL', salesforceENV.UALA_SALESFORCE_BOOTSTRAP_CODE_URL);
    loadScript(salesforceENV.UALA_SALESFORCE_BOOTSTRAP_CODE_URL, initSalesforceChat(sfUserData));
  }, [salesforceChatDisabled, user, venue, salesforceENV]);
});
