import { captureException } from '@sentry/core';

const getError = (e: any): Error => {
  if (!e) return new Error('UNKNOWN ERROR');
  if (!(e instanceof Error)) new Error(String(e));
  return e;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const fixStackTrace = (e: Error, target: Function) => {
  if ('captureStackTrace' in Error) Error.captureStackTrace(e, target);
  return e;
};

/**
 * Returns a Monad of the given function and send the error to Sentry and to the console
 */
export const withCatchToError =
  <F extends (...args: any) => any>(namespace: string, fn: F) =>
  (...args: Parameters<F>) => {
    try {
      return fn(...args);
    } catch (e) {
      const err = getError(e);

      err.message = `[${namespace}]: ${err.message}`;

      console.error(err);
      captureException(err);
      return err;
    }
  };
