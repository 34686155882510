import { Optional } from 'utils/utilityTypes';

export enum NODE_ENV {
  TEST = 'test',
  DEV = 'development',
  PROD = 'production',
}

export enum UALA_FEATURE_FLAG {
  AGENDA = 'UALA_FEATURE_AGENDA',
  CUSTOMERS = 'UALA_FEATURE_CUSTOMERS',
  CHECKOUT = 'UALA_FEATURE_CHECKOUT',
  TREATMENTS = 'UALA_FEATURE_TREATMENTS',
  STAFF_MEMBERS = 'UALA_FEATURE_STAFF_MEMBERS',
  PROMOTIONS = 'UALA_FEATURE_PROMOTIONS',
  TIMETABLE = 'UALA_FEATURE_TIMETABLE',
  COSTS = 'UALA_FEATURE_COSTS',
  MANAGER_TREND = 'UALA_FEATURE_MANAGER_TREND',
  MANAGER_DASHBOARD = 'UALA_FEATURE_MANAGER_DASHBOARD',
  MANAGER_COMPANY_REPORT = 'UALA_FEATURE_MANAGER_COMPANY_REPORT',
  MANAGER_COLLABORATORS_REPORT = 'UALA_FEATURE_MANAGER_COLLABORATORS_REPORT',
  MANAGER_CUSTOMERS_REPORT = 'UALA_FEATURE_MANAGER_CUSTOMERS_REPORT',
  MANAGER_STOCK_REPORT = 'UALA_FEATURE_MANAGER_STOCK_REPORT',
  MANAGER_INVENTORY_REPORT = 'UALA_FEATURE_MANAGER_INVENTORY_REPORT',
  WAREHOUSE = 'UALA_FEATURE_WAREHOUSE',
  PROFILE = 'UALA_FEATURE_PROFILE',
  MARKETING = 'UALA_FEATURE_MARKETING',
  ASSISTANT = 'UALA_FEATURE_ASSISTANT',
}

export interface HotjarEnv {
  [key: `UALA_HOTJAR_ID_${string}`]: Optional<string>;
}
export interface SentryEnv {
  UALA_SENTRY_ENV: Optional<string>;
  UALA_SENTRY_DSN: Optional<string>;
}

export interface UnleashEnv {
  UALA_UNLEASH_PROXY: Optional<string>;
  UALA_UNLEASH_CLIENT_KEY: Optional<string>;
  UNLEASH_FEATURES_EP: Optional<string>;
  UNLEASH_FEATURES_ARCHIVE_EP: Optional<string>;
}

export interface SegmentEnv {
  UALA_SEGMENT_KEY: Optional<string>;
}

export interface StatisticsEnv {
  UALA_STATISTICS_ENDPOINT: string;
  UALA_STATISTICS_SUBDOMAIN: Optional<string>;
  UALA_STATISTICS_KEY: Optional<string>;
}

export type MQTT_ENVOption = 'HOST' | 'PORT' | 'SSL';
export interface MQTTEnv {
  UALA_MQTT_HOST: Optional<string>;
  UALA_MQTT_PORT: Optional<string>;
  UALA_MQTT_SSL: Optional<string>;
}

export interface ApiEnv {
  UALA_API_KEY: Optional<string>;
  UALA_API_ENDPOINT: string;
  UALA_API_SUBDOMAIN: Optional<string>;
}

export interface POEditorEnv {
  POEDITOR_TOKEN: Optional<string>;
}

export interface SalesforceEnv {
  UALA_SALESFORCE_ORG_ID: Optional<string>;
  UALA_SALESFORCE_ESW_CONFIG_DEV_NAME: Optional<string>;
  UALA_SALESFORCE_SITE_URL: Optional<string>;
  UALA_SALESFORCE_SCRT2URL: Optional<string>;
  UALA_SALESFORCE_BOOTSTRAP_CODE_URL: Optional<string>;
}

export interface TWPROBaseEnv {
  UALA_REMOTES_REGISTRY_URL: string;

  UALA_TITLE: Optional<string>;
  UALA_DESCRIPTION: Optional<string>;
  UALA_FAVICON_PATH: Optional<string>;

  UALA_WAREHOUSE_URL: Optional<string>;
  UALA_ALLOW_PARAMS_CUSTOMIZATION: Optional<string>;

  UALA_ELECTRONIC_INVOICE_RECIPIENT_CODE: Optional<string>;

  /** @deprecated */
  UALA_CMS_ENDPOINT: Optional<string>;

  UALA_UPGRADE_DISABLED: Optional<string>;

  UALA_HOMEPAGE: Optional<string>;
  UALA_SALON_REFERRAL_URL: Optional<string>;
  UALA_FEATURE_ALL: Optional<string>;
}

export type AppEnv = TWPROBaseEnv & //
  ApiEnv &
  MQTTEnv &
  StatisticsEnv &
  UnleashEnv &
  HotjarEnv &
  SentryEnv &
  SegmentEnv &
  SalesforceEnv &
  POEditorEnv;

const ENV: AppEnv = ((window && window.REACT_APP_SETTINGS) || {}) as unknown as AppEnv;

if (process.env.NODE_ENV === NODE_ENV.DEV || process.env.NODE_ENV === NODE_ENV.TEST) {
  Object.keys(process.env).forEach((k?: string) => {
    if (!(k && /^REACT_APP/.test(k))) return;
    if (process.env[k] === undefined) return;

    const key = k.replace('REACT_APP_', '') as unknown as keyof AppEnv;
    ENV[key] = process.env[k] ?? '';
  });
}

export default ENV;
