/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { ValidURLString } from 'utils/tests/mockServerTools';

//@ts-ignore
type LoadAddEventListener<K extends keyof HTMLElementEventMap> = HTMLScriptElement['addEventListener'];
export type LoadListener = Parameters<LoadAddEventListener<'load'>>[1];
export type RegExpValidation = [RegExp, string];
export type SalesforceChatPreChatFields = {
  externalUserFullName: string;
  externalUserId: number;
  externalVenueId: number;
  externalLanguage: string;
  externalSource: string;
  externalUserEmail: string;
  externalCountry: string;
  profileAccessToken: string;
};
type SnippetConfig = {
  scrt2URL: string;
  hideChatButtonOnLoad: boolean;
};
type embeddedservice_bootstrap_type = {
  utilAPI: {
    launchChat: () => void;
  };
  init: (orgId: string, eswConfigDevName: string, siteURL: ValidURLString, snippetConfig: SnippetConfig) => void;
  settings: {
    language: string;
  };
  prechatAPI: {
    setHiddenPrechatFields: (fields: SalesforceChatPreChatFields) => void;
  };
};

declare global {
  interface Window {
    embeddedservice_bootstrap: embeddedservice_bootstrap_type;
  }
}
export class SalesforceChatConfigError extends Error {}
