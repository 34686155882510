import ENV, { MQTTEnv, MQTT_ENVOption } from 'env';

export default function getMQTTOption(option: MQTT_ENVOption): string | null {
  const key: keyof MQTTEnv = `UALA_MQTT_${option}`;

  if (ENV.UALA_ALLOW_PARAMS_CUSTOMIZATION && window.localStorage) {
    if (window.localStorage.getItem('UALA_OVERRIDE_PARAMS') === 'true') {
      return window.localStorage.getItem(key);
    }
  }

  return (ENV && ENV[key]) || null;
}
