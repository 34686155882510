import { dispatchCustomEvent } from 'customEvents';
import { intervalCheck } from 'utils/intervalCheck';

import { SALESFORCE_CHAT_ERROR, SALESFORCE_CHAT_OPENED, SALESFORCE_CHAT_OPENED_REQUEST } from './customEvents';
import { onSalesforceError, buildSalesforceChatError } from './errorHandling';

const checkSalesforceChatOpened = () => {
  return true;
};
const onSFChatFailToOpen = () => {
  dispatchCustomEvent(SALESFORCE_CHAT_ERROR);
  onSalesforceError(buildSalesforceChatError('Salesforce not opening!'));
};
const onSFChatSucceedOpening = () => dispatchCustomEvent(SALESFORCE_CHAT_OPENED);
export const openSalesforce = () => {
  dispatchCustomEvent(SALESFORCE_CHAT_OPENED_REQUEST);

  window.embeddedservice_bootstrap.utilAPI.launchChat();

  intervalCheck({
    check: checkSalesforceChatOpened,
    onFailure: onSFChatFailToOpen,
    onSuccess: onSFChatSucceedOpening,
    step: 250,
    timeout: 10000,
  });
};
