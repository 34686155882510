export const SALESFORCE_CHAT_ERROR = 'salesforceChat:error';
export const SALESFORCE_CHAT_OPENED_REQUEST = 'salesforceChat:openedRequest';
export const SALESFORCE_CHAT_OPENED = 'salesforceChat:opened';

export const salesforceChatCustomEvents = [
  SALESFORCE_CHAT_ERROR,
  SALESFORCE_CHAT_OPENED_REQUEST,
  SALESFORCE_CHAT_OPENED,
] as const;
export type SalesforceChatCustomEvents = (typeof salesforceChatCustomEvents)[number];
