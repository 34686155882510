import { captureException } from '@sentry/core';
import { dispatchCustomEvent } from 'customEvents';
import {
  INTERCOM_ERROR,
  INTERCOM_OPENED,
  INTERCOM_OPENED_REQUEST,
  IntercomCustomEvents,
} from 'utils/fixIntercomLunch/customEvents';
import { intervalCheck } from 'utils/intervalCheck';
import { Logger } from 'utils/logger';
import { withCatchToError } from 'utils/sentryUtils';

const logger = new Logger('fixIntercom');

const INTERCOM_LUNCHER_SELECTOR = '.uala-chat-support';
const NOT_OPENED_TIMEOUT = 5000;
class IntercomError extends Error {
  public intercomStatus: string;

  public constructor(message: string, intercomStatus: Window['intercomSettings'] & { booted: boolean }) {
    super(message);
    try {
      this.intercomStatus = JSON.stringify(intercomStatus, null, '  ');
    } catch (e) {
      this.intercomStatus = 'cannot stringify intercom status';
    }
  }
}
const trackIntercomError = (eventName: IntercomCustomEvents, error: IntercomError) => {
  dispatchCustomEvent(eventName);
  console.error(error);
  captureException(error);
};
const buildIntercomError = (message: string) =>
  new IntercomError(message, {
    ...window.intercomSettings,
    booted: window.Intercom.booted,
  });

const trackIntercomNotPresent = () => trackIntercomError(INTERCOM_ERROR, buildIntercomError('Intercom not present!'));
const trackIntercomNotBooted = () => trackIntercomError(INTERCOM_ERROR, buildIntercomError('Intercom not booted!'));
const trackIntercomNotOpening = () => trackIntercomError(INTERCOM_ERROR, buildIntercomError('Intercom not opening!'));
const trackIntercomOpened = () => dispatchCustomEvent(INTERCOM_OPENED);

const isIntercomOpened = (): boolean => {
  const intercomFrameElm = document.querySelector('.intercom-messenger-frame');
  if (!intercomFrameElm) return false;

  const boundingRect = intercomFrameElm.getBoundingClientRect();
  if (boundingRect.width === 0 || boundingRect.height === 0) return false;

  return true;
};

const checkForIntercomOpening = () =>
  intervalCheck({
    check: withCatchToError('isIntercomOpened', isIntercomOpened),
    onFailure: trackIntercomNotOpening,
    onSuccess: trackIntercomOpened,
    step: Math.trunc(NOT_OPENED_TIMEOUT / 20),
    timeout: NOT_OPENED_TIMEOUT,
  });

window.document.addEventListener('click', (event) => {
  if (!event.target) return;
  if (!(<HTMLElement>event.target).closest(INTERCOM_LUNCHER_SELECTOR)) return;

  dispatchCustomEvent(INTERCOM_OPENED_REQUEST);

  if (!('Intercom' in window)) return trackIntercomNotPresent();
  if (!Intercom.booted) trackIntercomNotBooted(); // NO RETURN WE T

  window.Intercom('show');
  logger.debug('sent to open');

  checkForIntercomOpening();
});

logger.debug('Initiated!!!');
