import { withCatchToError } from 'utils/sentryUtils';
type IntervalCheckCallback = (...args: any) => any;
type IntervalCheckOptions = {
  check: () => boolean;
  onSuccess: IntervalCheckCallback;
  onFailure: IntervalCheckCallback;
  step?: number;
  timeout?: number;
};

const DEFAULT_OPTIONS: Partial<IntervalCheckOptions> = { step: 250, timeout: 10000 };

export const intervalCheck: (options: IntervalCheckOptions) => void = (options) => {
  const { check, onSuccess, onFailure, step, timeout } = { ...DEFAULT_OPTIONS, ...options };

  const stop = () => {
    clearTimeout(watchdogHandler);
    clearInterval(tickHandler);
  };

  const doCheck = () => {
    if (!check()) return false;

    withCatchToError('intervalCheck', onSuccess)();
    stop();
    return true;
  };

  const tickHandler = setInterval(doCheck, step);

  const watchdogHandler = setTimeout(() => {
    if (!doCheck()) onFailure();
    stop();
  }, timeout);
};
