import {
  CHANGE_LOCALE,
  LOCAL_GRACE_PERIOD,
  SESSIONS_LOGOUT,
  SESSIONS_RESET_VENUE_DATA,
  SESSIONS_SIGNIN_REQUEST,
  SESSIONS_SIGNIN_RESPONSE,
  SWITCH_CURRENT_VENUE,
  UPDATED_SINCE_RECEIVED,
  USER_ACL_PERMISSIONS_RESPONSE,
} from 'actionTypes';

import { push } from 'connected-react-router';
import moment from 'moment';
import { API_ERROR, apiGet, apiPost } from './data_providers/api';
import { getCurrentVenueId, getUpdatedSinceByVenue } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getMultiVenueSidebarDisabled } from 'selectors/getUserMultiVenue';
import { stopSynch } from 'utils/synch';
import { isCypress } from 'utils/cypress';
import { persistor } from 'store';

const gracePeriodAction = (gracePeriodBannerDismissDate, venue_id) => ({
  type: LOCAL_GRACE_PERIOD,
  gracePeriodBannerDismissDate,
  venue_id,
});

export const setGracePeriodBannerDismiss = () => (dispatch, getState) => {
  const venueId = getCurrentVenueId(getState());
  if (!venueId) return;

  const gracePeriodBannerDismissDate = new Date().getTime();
  dispatch(gracePeriodAction(gracePeriodBannerDismissDate, venueId));
};

const changeLocaleAction = ({ venue_id }) => ({
  type: CHANGE_LOCALE,
  venue_id,
});

export const changeLocale = () => (dispatch, getState) => {
  const venue_id = getCurrentVenueId(getState());
  if (!venue_id) return;

  dispatch(changeLocaleAction({ venue_id }));
};

const sessionsLogoutAction = () => ({
  type: SESSIONS_LOGOUT,
});

export const logoutRedirect = (impersonate = false) => {
  return (dispatch, getState) => {
    // in cypress all non-mocked responses responds with a 401, so we need to avoid that the page redirects to login in that cases
    if (isCypress()) return;
    dispatch(sessionsLogoutAction());
    dispatch(push('/'));
    dispatch(stopSynch());
    if (!impersonate) {
      persistor.purge();
      window.location.reload(); // this is required in order to close 3rd party sessions (aka salesforce chat)
    }
  };
};

export const sessionsLogout = () => {
  return (dispatch, getState) => {
    dispatch(
      apiPost({
        method: 'DELETE',
        path: '/sessions.json',
        onResponse(response) {
          if (response.success) {
            dispatch(logoutRedirect());
            sessionStorage.removeItem('auth_token');
            sessionStorage.removeItem('venue_id');
          }
        },
      })
    );
  };
};

const sessionsSigninRequestAction = ({ email, password, auth_token }) => ({
  type: SESSIONS_SIGNIN_REQUEST,
  email,
  password,
  auth_token,
});

const sessionsSigninResponseAction = ({ error, user }) => ({
  type: SESSIONS_SIGNIN_RESPONSE,
  user,
  error,
});

export const sessionsSignin =
  ({ email, password, auth_token = null, impersonate }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(sessionsSigninRequestAction({ email, password, auth_token }));

      dispatch(
        apiPost({
          path: '/sessions.json',
          guest: true,
          data: auth_token
            ? { auth_token }
            : {
                user: {
                  email,
                  password,
                },
              },
          onResponse(response) {
            if (!response.success) {
              dispatch(sessionsSigninResponseAction({ error: [].concat(response.info || [])[0] }));
              return reject();
            }

            const user = { ...response.data, impersonate };
            if (!user.email && email) {
              user.email = email;
            }

            dispatch(sessionsSigninResponseAction({ user }));

            resolve(user);
          },
          handleAllErrors: true,
          onError(error) {
            setTimeout(() => {
              const signinError = error?.error ?? error?.message ?? API_ERROR.UNKNOWN;
              dispatch(sessionsSigninResponseAction({ error: signinError }));
            }, 300);
            reject();
          },
        })
      );
    });

const refreshPermissionsResponseAction = ({ user }) => ({
  type: USER_ACL_PERMISSIONS_RESPONSE,
  user,
});

export const sessionsRefreshPermissions =
  ({ auth_token = null }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(
        apiPost({
          path: '/sessions.json',
          guest: true,
          data: { auth_token },
          onResponse(response) {
            if (!response.success) {
              return reject();
            }

            if (response.data && response.data.acl_groups) {
              dispatch(refreshPermissionsResponseAction({ user: response.data }));
            }
            resolve(response);
          },
          onError(error) {},
        })
      );
    });

export const sessionsRefreshVenues = () => (dispatch, getState) => {
  const auth_token = getCurrentUserToken(getState());
  dispatch(sessionsRefreshPermissions({ auth_token }));
};

export const sessionsPasswordResetRequest =
  ({ email }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(
        apiPost({
          path: `/passwords.json?return_url=${encodeURIComponent(window.location.hostname)}`,
          guest: true,
          data: {
            user: {
              email,
            },
          },
          onResponse(response) {
            if (!response.success) {
              return reject([].concat(response.info || [])[0]);
            }

            resolve([].concat(response.info || [])[0]);
          },
          handleAllErrors: true,
          onError(error) {
            reject('Error');
          },
        })
      );
    });

export const sessionsPasswordReset =
  ({ password, reset_password_token }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/passwords.json`,
          guest: true,
          data: {
            user: {
              password,
              reset_password_token,
            },
          },
          customErrorResponseStrategy: () => true,
          onResponse(response) {
            if (response.errors) {
              if (response.errors.password) {
                return reject(response.errors.password);
              } else if (response.errors.reset_password_token) {
                return reject(response.errors.reset_password_token);
              } else {
                return reject();
              }
            }

            dispatch(sessionsSignin({ auth_token: response.auth_token }));
            resolve();
          },
          handleAllErrors: true,
          onError(error) {
            reject('Error');
          },
        })
      );
    });

export const sessionsVerifyCredentials =
  ({ email, password }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(
        apiPost({
          path: '/sessions.json',
          guest: true,
          data: {
            user: {
              email,
              password,
            },
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: [].concat(response.info || [])[0] });
            }

            const user = { ...response.data, email };

            resolve({ user });
          },
          handleAllErrors: true,
          onError(error) {
            reject(error);
          },
        })
      );
    });

export const initUserData = () => (dispatch, getState) => {
  /**
   * Refresh ACL permissions
   */
  const auth_token = getCurrentUserToken(getState());
  dispatch(sessionsRefreshPermissions({ auth_token }));

  const venue_id = getCurrentVenueId(getState());

  dispatch(
    apiGet({
      path: `/venues/${venue_id}.json`,
    })
  );
};

export const updatedSinceReceived = ({ venue_id, timestamp }) => ({
  type: UPDATED_SINCE_RECEIVED,
  venue_id,
  timestamp,
});

const sessionsResetVenueDataAction = ({ venue_id }) => ({
  type: SESSIONS_RESET_VENUE_DATA,
  venue_id,
});

const switchCurrentVenueAction = ({ venue_id }) => ({
  type: SWITCH_CURRENT_VENUE,
  venue_id,
});

export const switchCurrentVenue =
  ({ venue_id }) =>
  (dispatch, getState) => {
    const updatedSinceByVenue = getUpdatedSinceByVenue(getState());
    const isMultiVenueSidebarDisabled = getMultiVenueSidebarDisabled(getState());

    if (updatedSinceByVenue[venue_id] && moment().diff(updatedSinceByVenue[venue_id], 'hours') > 8) {
      dispatch(sessionsResetVenueDataAction({ venue_id }));
    }
    if (!isMultiVenueSidebarDisabled) {
      dispatch(switchCurrentVenueAction({ venue_id }));
    }
  };

export const venueSetTreatmentResponsible = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!auth || !venue_id) {
      return reject({ code: 400 });
    }

    dispatch(
      apiPost({
        path: '/venues/' + venue_id + '/set_treatment_responsible_date.json',
        auth,
        data: {
          treatment_responsible_date: new Date().toISOString(),
        },
        onResponse(response) {
          if (!response.success) {
            return reject({ error: [].concat(response.info || [])[0] });
          }

          resolve();
        },
        onError(error) {
          reject(error);
        },
      })
    );
  });
