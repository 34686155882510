import { LoadListener } from '@features/SalesforceChat/types';
import { strHashCode } from 'utils/strHashCode';

export const loadScript = (src: string, onLoadCb: LoadListener) => {
  const id = `ls_${strHashCode(src)}`;
  if (document.getElementById(id)) return;

  const script: HTMLScriptElement = document.createElement('script');
  script.id = id;
  script.type = 'text/javascript';
  script.src = src;
  script.addEventListener('load', onLoadCb);
  document.body.appendChild(script);
};
