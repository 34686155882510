import { useSelector } from 'react-redux';

import { ACTIVE_FLAGS, useFlag } from 'feature-flags';
import { Icon } from 'components/core';
import { getCurrentVenueCountrySegmentKey } from 'selectors/getCurrentVenue';

import { ActionItem } from './NavBar.theme';

export const IntercomChatLauncher = () => {
  const segment_key = useSelector(getCurrentVenueCountrySegmentKey);
  const intercomChatEnabled = useFlag(ACTIVE_FLAGS.FE_CHAT_INTERCOM);

  if (!(intercomChatEnabled && segment_key)) return null;

  return (
    <ActionItem desktop className="uala-chat-support">
      <Icon type="chat" color="blue" title="Intercom" />
    </ActionItem>
  );
};

export const SalesforceChatLauncher = () => {
  const salesforceChatEnabled = useFlag(ACTIVE_FLAGS.FE_CHAT_SALESFORCE);
  if (!salesforceChatEnabled) return null;

  return (
    salesforceChatEnabled && (
      <ActionItem desktop className="salesforce-chat-support">
        <Icon type="chat" color="blue" title="Salesforce" />
      </ActionItem>
    )
  );
};
