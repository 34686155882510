import React from 'react';
import { injectIntl } from 'react-intl';
import { Link, matchPath, withRouter } from 'react-router-dom';

import { Badge, Icon, Section, Text } from 'components/core';
import AccordionItem from 'components/lib/AccordionItem';
import { LanguageSwitcher } from 'components/lib/LanguageSwitcher';
import { StateRecorder } from 'components/lib/StateRecorder';
import { RECORDING_FLAG } from 'components/lib/StateRecorder/config';
import ProductStashTrigger from 'components/productstash_next';
import { Trigger as PromoFlashTrigger } from 'components/promo_flash';
import { ProvisioningUpsellingButton } from 'components/provisioning_next';
import { ReferralLink } from 'components/referral_link/ReferralLink';
import { NODE_ENV } from 'env';
import Remotes from 'host/Remotes';
import checkFeature from 'utils/checkFeature';
import { useIsMobileViewport } from 'utils/hooks/useViewPort';
import { isElectron } from 'utils/native/cash';
import { electronGetGlobal } from 'utils/native/electronUtils';
import RELEASE_CONFIG from 'version.json';

import items from './NavBar.data';
import {
  ActionItem,
  Actions,
  buildSectionTitleLink,
  MenuTrigger,
  Overlay,
  SectionTitle,
  SidebarMenu,
  NavBar as StyledNavBar,
} from './NavBar.theme';
import { IntercomChatLauncher, SalesforceChatLauncher } from './ChatLauncher';

const TEST_VERSION_FOR_SNAPSHOT = 'vX.Y.Z';

const stopPropagation = (e) => e.stopPropagation();

const PromoFlash = () => {
  const isMobile = useIsMobileViewport();
  return <ActionItem>{isMobile && <PromoFlashTrigger mobile testId="promo-flash-trigger-mobile" />}</ActionItem>;
};

const ProductStashAction = () => {
  return (
    <ActionItem desktop>
      <ProductStashTrigger />
    </ActionItem>
  );
};

const StateRecorderAction = () => {
  return (
    electronGetGlobal(RECORDING_FLAG) && (
      <ActionItem desktop>
        <StateRecorder />
      </ActionItem>
    )
  );
};

const TitleText = ({ intl, children, badgeIntl }) => (
  <>
    <Text size={12} bold uppercase spacing={1} intl={intl}>
      {children}
    </Text>
    {badgeIntl && (
      <Badge primary size={'short'}>
        <Text black size={8} color="white" intl={badgeIntl} />
      </Badge>
    )}
  </>
);

const SubTitleText = ({ intl, intlValues, children, active, venue }) => (
  <Section marginLeft={2}>
    <Text
      size={12}
      bold
      uppercase
      spacing={1.71}
      color={active ? 'blue' : 'text2'}
      intl={typeof intl === 'function' ? intl({ venue }) : intl}
      intlValues={intlValues ? intlValues({ venue }) : undefined}
    >
      {children}
    </Text>
  </Section>
);

const SectionTitleLinkContent = ({ content, venue, checkCurrentVenueActiveFlag, isDisabled }) => {
  const badgeIntl = content.badgeIntl ? content.badgeIntl({ checkCurrentVenueActiveFlag }) : null;
  return (
    <>
      {isDisabled ? (
        <Icon type="blocked" color="red" />
      ) : (
        <Icon type={content.icon} color={content.iconColor || 'text2'} />
      )}
      {content.title ? (
        <TitleText badgeIntl={badgeIntl}>{content.title}</TitleText>
      ) : content.titleIntl ? (
        typeof content.titleIntl === 'function' ? (
          <TitleText intl={content.titleIntl({ venue })} badgeIntl={badgeIntl} />
        ) : (
          <TitleText intl={content.titleIntl} badgeIntl={badgeIntl} />
        )
      ) : (
        '###'
      )}
    </>
  );
};

export class NavBar extends React.Component {
  generateSectionTitleLinkWithProps(item) {
    const { venue } = this.props;
    let SectionTitleLink;
    let linkProps;

    if (item.isExternal && item.isExternal()) {
      SectionTitleLink = buildSectionTitleLink('a');
      linkProps = { href: typeof item.to === 'function' ? item.to({ venue }) : item.to, target: '_blank' };
    } else {
      SectionTitleLink = buildSectionTitleLink(Link);
      linkProps = { to: typeof item.to === 'function' ? item.to({ venue }) : item.to };
    }

    return { SectionTitleLink, linkProps };
  }

  onVersionClick() {
    const { isNf525Compilant, openNf525CertificationInfoModal, venue } = this.props;
    if (isNf525Compilant) {
      openNf525CertificationInfoModal(
        venue.country.nf525_certificate.full_number,
        venue.country.nf525_certificate.file_url
      );
    }
  }

  getItems() {
    return this.props.items || items || [];
  }

  getVersion() {
    const { venue, isNf525Compilant, isPortugueseFinancialAuthorityCompliant } = this.props;
    switch (true) {
      case process.env.NODE_ENV === NODE_ENV.TEST:
        return TEST_VERSION_FOR_SNAPSHOT;
      case isNf525Compilant || isPortugueseFinancialAuthorityCompliant: {
        const platform_version = venue?.country?.platform_version || '';
        return `v${platform_version}`;
      }
      default:
        return RELEASE_CONFIG.version;
    }
  }

  render() {
    const {
      isOpen,
      onLogout,
      onClick,
      location,
      checkPermission = () => 'none',
      venue,
      checkCurrentVenueActiveFlag,
      detailed_monitoring_enabled,
      onDetailedMonitorClick,
      user,
    } = this.props;

    const { customer_care_phone_number } = venue?.country || {};
    const version = this.getVersion();

    let section_items_counter = 0;

    return (
      <StyledNavBar className="NavBar" open={isOpen} onClick={onClick}>
        <Overlay isOpen={isOpen} />
        <MenuTrigger>
          <Icon type="close" color="blue" />
        </MenuTrigger>
        <Actions>
          <IntercomChatLauncher />
          <SalesforceChatLauncher />
          <ProductStashAction />
          <StateRecorderAction />
          <PromoFlash />
          <ActionItem smallDesktop>
            <ProvisioningUpsellingButton noIcons />
          </ActionItem>
        </Actions>
        <SidebarMenu>
          {this.getItems().map((item, k) => {
            if (item.separator) {
              if (!section_items_counter) return null;

              section_items_counter = 0;
              return <Section key={k} height={20} />;
            }

            if (!item.isVisible({ venue, checkFeature, checkPermission })) {
              return null;
            }

            section_items_counter += 1;

            const showChildren =
              item.children &&
              item.children.length &&
              item.children.find((child) => child.isVisible({ venue, checkFeature, checkPermission }));

            const isItemDisabled = item.isDisabled && item.isDisabled({ venue });

            if (showChildren) {
              return (
                <AccordionItem
                  key={k}
                  isDisabled={isItemDisabled}
                  startOpen={
                    !!matchPath(location.pathname, {
                      path: item.children.reduce(
                        (activePaths, child) => activePaths.concat(child.activePaths || []),
                        []
                      ),
                    })
                  }
                  title={
                    <SectionTitle isDisabled={isItemDisabled}>
                      <SectionTitleLinkContent
                        content={item}
                        venue={venue}
                        checkCurrentVenueActiveFlag={checkCurrentVenueActiveFlag}
                        isDisabled={isItemDisabled}
                      />
                    </SectionTitle>
                  }
                  content={
                    <Section>
                      {item.children.map((child, key) => {
                        if (!child.isVisible({ venue, checkFeature, checkPermission })) {
                          return null;
                        }

                        const { SectionTitleLink, linkProps } = this.generateSectionTitleLinkWithProps(child);

                        return (
                          <SectionTitleLink key={key} {...linkProps}>
                            <SubTitleText
                              intl={child.titleIntl}
                              intlValues={child.titleIntlValues || undefined}
                              venue={venue}
                              active={
                                matchPath(location.pathname, { path: child.activePaths, exact: true }) ? 'true' : null
                              }
                            />
                          </SectionTitleLink>
                        );
                      })}
                    </Section>
                  }
                />
              );
            }

            const { SectionTitleLink, linkProps } = this.generateSectionTitleLinkWithProps(item);

            return (
              <SectionTitleLink
                key={k}
                active={matchPath(location.pathname, { path: item.activePaths || [] }) ? 'true' : null}
                disabled={isItemDisabled}
                {...linkProps}
              >
                <SectionTitleLinkContent
                  content={item}
                  venue={venue}
                  checkCurrentVenueActiveFlag={checkCurrentVenueActiveFlag}
                  isDisabled={isItemDisabled}
                />
              </SectionTitleLink>
            );
          })}

          <Remotes>
            {(remote) => {
              if (remote.config?.autoAppMenuItem) {
                const SectionTitleLink = buildSectionTitleLink(Link);
                return (
                  <SectionTitleLink key={remote.scope} to={'/apps/' + remote.scope}>
                    <Icon type={remote.config?.appIcon || 'compass'} color="text2" />
                    <TitleText>{remote.name}</TitleText>
                  </SectionTitleLink>
                );
              }
              return null;
            }}
          </Remotes>

          <Section height={20} />

          <SectionTitle
            onClick={() => {
              window.customConfirm({
                content: this.props.intl.formatMessage({ id: 'confirm.logout' }),
                onConfirm: onLogout,
              });
            }}
          >
            <Icon type="logout" color="text2" />
            <TitleText intl="navbar.logout" />
          </SectionTitle>

          <ReferralLink />

          <Section padding={1.1}>
            <Section displayFlex>
              <Text size={12} bold spacing={1} color="text2">
                {customer_care_phone_number && <Icon padding={5} type="phone" color="text2" size={12} />}
                {customer_care_phone_number || ''}
              </Text>
            </Section>
            <Section displayFlex paddingTop={0.5} flexDirection="column" alignItems="stretch" onClick={stopPropagation}>
              {venue && user && (
                <Section flex marginBottom={0.25}>
                  <Text size={12} bold spacing={1} color="text2">
                    {venue.id && <Icon padding={5} type="man" color="text2" size={12} />}
                    {venue.id} - {user.id}
                  </Text>
                </Section>
              )}
              <Section flex justifyContent="space-between" alignItems="center" marginLeft={0.25} marginRight={0.25}>
                {isElectron() && detailed_monitoring_enabled && (
                  <Icon padding={5} type="gear" color="text2" size={12} onClick={onDetailedMonitorClick} />
                )}
                <Section onClick={() => this.onVersionClick()}>
                  <Text size={12} bold spacing={1} color="text2">
                    {version}
                  </Text>
                </Section>
                <LanguageSwitcher position="top" />
              </Section>
            </Section>
          </Section>
        </SidebarMenu>
      </StyledNavBar>
    );
  }
}

export default withRouter(injectIntl(NavBar));
