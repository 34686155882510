/**
 * @info
 * --------------------------------------
 * THIS FILE IS GENERATED AUTOMATICALLY
 * By running  "<yarn | npm run > generate:featureFlags"
 * MODIFY THIS AT YOUR OWN RISK !!
 * --------------------------------------
 *
 * @description
 * This file contains the feature flags for the project https://unleash.uala.dev.
 **/
export const ACTIVE_FLAGS = {
  /**
   *
   * @description TWPRO-3166-FE_put_hygenic_activity_description_section_under_unleash_feature_flag
   *
   */
  TWPRO_3166: 'TWPRO_3166',
  /**
   *
   * @description TWPRO-3240-add-link-to-external-referral-page
   *
   */
  TWPRO_3240: 'TWPRO_3240',
  /**
  *
  * @description CANCELLATION PROTECTION
TWPRO-3902-add-credit-card-on-appointment-creation
  *
  */
  TWPRO_3902: 'TWPRO_3902',
  /**
   *
   * @description https://wahanda.atlassian.net/browse/TWPRO-1416
   *
   */
  TWPRO_1416: 'TWPRO_1416',
  /**
  *
  * @description Cancel Protection SMS Flow

https://wahanda.atlassian.net/browse/TWPRO-4231

  *
  */
  TWPRO_4801: 'TWPRO_4801',
  /**
   *
   * @description TWPRO-5200-pending-appointment-list-component
   *
   */
  TWPRO_4770: 'TWPRO_4770',
  /**
   *
   * @description TWPRO-6256-display-receipt-cancel-button-given-FF
   *
   */
  TWPRO_6256: 'TWPRO_6256',
  /**
   *
   * @description Shows Promo only valid on new customers toggle for promotions
   *
   */
  TWPRO_6160: 'TWPRO_6160',
  /**
   *
   * @description TWPRO-8075-ios-custom-autofill-modal
   *
   */
  TWPRO_8075: 'TWPRO_8075',
  /**
   *
   * @description Enable Checkout to call the Backend via a Debounce Mechanism
   *
   */
  TWPRO_8164: 'TWPRO_8164',
  /**
   *
   * @description TWPRO-6714-fe-create-new-billing-page-invoices-ui
   *
   */
  TWPRO_6714: 'TWPRO_6714',
  /**
   *
   * @description TWPRO-6450-prebill-receipt
   *
   */
  TWPRO_6450: 'TWPRO_6450',
  /**
   *
   * @description TWPRO-7164-patch-test-grid-behaviour
   *
   */
  TWPRO_7164: 'TWPRO_7164',
  /**
   *
   * @description TWPRO-7644-create-utils-to-filter-staff-member-columns
   *
   */
  TWPRO_7644: 'TWPRO_7644',
  /**
   *
   * @description TWPRO-7289-hide-charge-customizable-amount-online-bookings
   *
   */
  TWPRO_7289: 'TWPRO_7289',
  /**
   *
   * @description
   *
   */
  TWPRO_STORE: 'TWPRO_STORE',
  /**
   *
   * @description TWPRO-7704-unloadable-with-barcode-gun
   *
   */
  TWPRO_7704: 'TWPRO_7704',
  /**
   *
   * @description https://wahanda.atlassian.net/browse/TWPRO-7857
   *
   */
  TWPRO_7857: 'TWPRO_7857',
  /**
   *
   * @description TWPRO-7619-filters-section-in-the-mobile-drawer
   *
   */
  TWPRO_7619: 'TWPRO_7619',
  /**
   *
   * @description Toggle to decide to send or not confirmation SMS. If country ID is in the list then confirmation SMS should be sent.
   *
   */
  TWPRO_9607: 'TWPRO_9607',
  /**
   *
   * @description Export customers
   *
   */
  TWPRO_8887: 'TWPRO_8887',
  /**
   *
   * @description Enabled will puts Profile > Settings > Payment Methods > Add a new debit or credit card Button in Maintenance Mode
   *
   */
  TWPRO_9574: 'TWPRO_9574',
  /**
   *
   * @description Enable MQTT
   *
   */
  TWPRO_9610: 'TWPRO_9610',
  /**
  *
  * @description Enable / Disables DAC7 Business Details section.
Intended to control to which countries we enable this section as it requires different developments by country
  *
  */
  TWPRO_9390: 'TWPRO_9390',
  /**
  *
  * @description [DEVELOPMENT HELPER] DAC7 Frontend Edit Profile > Business Details
For those Countries that DO NOT have a form defined, it will show the 4 fields vatNumber, companyNumber, fiscalCode, taxIdNumber.

THIS FEATURE FLAG IS INTENDED FOR DEVELOPMENT PROPOSES ONLY!
  *
  */
  TWPRO_10206: 'TWPRO_10206',
  /**
   *
   * @description Enables or Disables the use of intercom chat
   *
   */
  FE_CHAT_INTERCOM: 'FE_CHAT_INTERCOM',
  /**
   *
   * @description Feature flag for new service logics insisting on VTG instead of VT.
   *
   */
  TWPRO_6505: 'TWPRO_6505',
  /**
   *
   * @description TWPRO-1665-services-categories
   *
   */
  TWPRO_1665: 'TWPRO_1665',
  /**
   *
   * @description Enable AddOnsFee synch to Treatwell ledger (for country)
   *
   */
  TWPRO_5953: 'TWPRO_5953',
  /**
   *
   * @description Disable Direct Debit agreement data synchronization with Finance Services
   *
   */
  TWPRO_10263: 'TWPRO_10263',
  /**
   *
   * @description Enable or Disables the use of Salesforce Chat
   *
   */
  FE_CHAT_SALESFORCE: 'FE_CHAT_SALESFORCE',
  /**
   *
   * @description TEF-666-KYC-modal-and-flash-message
   *
   */
  TEF_666: 'TEF_666',
  /**
   *
   * @description To add more logs and metrics around MQTT functions
   *
   */
  TWPRO_9672: 'TWPRO_9672',
  /**
   *
   * @description TW Store navbar menu item new badge
   *
   */
  TWPRO_STORE_NEW_BADGE: 'TWPRO_STORE_NEW_BADGE',
  /**
   *
   * @description Publish bundle packages in Treatwell marketplace
   *
   */
  TWPRO_2067: 'TWPRO_2067',
  /**
   *
   * @description
   *
   */
  TWPRO_9346: 'TWPRO_9346',
  /**
   *
   * @description AB testing for SMS notifications enabler
   *
   */
  TWPRO_9309: 'TWPRO_9309',
  /**
   *
   * @description Prevent Admin to delete checkouts form AA
   *
   */
  TWPRO_7502: 'TWPRO_7502',
  /**
   *
   * @description Enable multiple bookings of treatment in the same order
   *
   */
  TWPRO_7609: 'TWPRO_7609',
  /**
   *
   * @description Advanced Packages
   *
   */
  TWPRO_7204: 'TWPRO_7204',
  /**
   *
   * @description Disable sms for notify_manager_of_appointments_from_web
   *
   */
  TWPRO_7073: 'TWPRO_7073',
  /**
   *
   * @description Enable "buy product" CTA
   *
   */
  TWPRO_4087: 'TWPRO_4087',
  /**
   *
   * @description MPWEB_1775
   *
   */
  MPWEB_1775: 'MPWEB_1775',
  /**
   *
   * @description Feature flag to use new service naming structure for MP.
   *
   */
  TWPRO_6502: 'TWPRO_6502',
  /**
   *
   * @description Allow to selectively enable discounted price validation.
   *
   */
  TWPRO_6210: 'TWPRO_6210',
  /**
   *
   * @description disable manager sms if customer cancel appointment or booking
   *
   */
  TWPRO_7413: 'TWPRO_7413',
  /**
   *
   * @description TWPRO-2444-petty-cash-balance-modal
   *
   */
  TWPRO_2444: 'TWPRO_2444',
  /**
   *
   * @description https://wahanda.atlassian.net/browse/TWPRO-1589
   *
   */
  TWPRO_1589: 'TWPRO_1589',
  /**
   *
   * @description Log unwrapped BE parameters
   *
   */
  TWPRO_6497: 'TWPRO_6497',
  /**
   *
   * @description Enable the SaaS users from changing the treatments in an appointment booked online
   *
   */
  TWPRO_7978: 'TWPRO_7978',
  /**
   *
   * @description Manually switch off support for checkout items containing deleted treatments
   *
   */
  TWPRO_6810: 'TWPRO_6810',
  /**
   *
   * @description Toggle to decide to send or not confirmation SMS. If venue ID is in the list then confirmation SMS should be sent.
   *
   */
  TWPRO_10011: 'TWPRO_10011',
  /**
   *
   * @description Enable credit card fields in Greek checkout signatures
   *
   */
  TWPRO_9892: 'TWPRO_9892',
  /**
   *
   * @description Toggle to decide to send or not cancelation SMS. If country ID is in the list then confirmation SMS should be sent.
   *
   */
  TWPRO_9766: 'TWPRO_9766',
  /**
   *
   * @description Enable Userpilot functions in SaaS
   *
   */
  TWPRO_8828: 'TWPRO_8828',
  /**
   *
   * @description Enable treatment variants toggle
   *
   */
  TWPRO_6188: 'TWPRO_6188',
} as const;
export type ActiveFlags = (typeof ACTIVE_FLAGS)[keyof typeof ACTIVE_FLAGS];
