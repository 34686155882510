import { captureException } from '@sentry/core';

const salesforceContext: Record<string, unknown> = {};

export const addToErrorContext = (key: string, data: unknown) => {
  salesforceContext[key] = data;
};

class SalesforceChatError extends Error {
  public context: string;

  public constructor(message: string) {
    super(message);
    try {
      this.context = JSON.stringify(salesforceContext, null, '  ');
    } catch (e) {
      this.context = 'cannot stringify salesforceContext';
    }
  }
}
export const buildSalesforceChatError = (message: string) => new SalesforceChatError(message);

export const onSalesforceError = (error: SalesforceChatError) => {
  console.error(error);
  captureException(error);
};
