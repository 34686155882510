import { Component, ErrorInfo, ReactElement } from 'react';
import ENV, { UALA_FEATURE_FLAG } from 'env';
import { Provider } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { store, history, persistor } from 'store';
import { withScope, captureException } from '@sentry/core';
import { PersistGate } from 'redux-persist/integration/react';
import SessionsProvider from 'providers/SessionsProvider';
import LayoutProvider from 'providers/LayoutProvider';
import HotjarProvider from 'providers/HotjarProvider';
import ModalsAndStatusProvider from 'providers/ModalsAndStatusProvider';
import DataProvider from 'providers/DataProvider';
import IntlProvider from 'providers/IntlProvider';
import ScrollToTopProvider from 'providers/ScrollToTopProvider';
// eslint-disable-next-line no-restricted-imports
import { theme, ThemeProvider } from 'styles';
import DomainProvider from './providers/DomainProvider';
import VenueProvider from './providers/VenueProvider';
import AcceptanceFormsProvider from 'providers/AcceptanceFormsProvider';
import GlobalsProvider from 'providers/GlobalsProvider';
import { UnleashConfig } from 'feature-flags/unleash.config';
// eslint-disable-next-line no-restricted-imports
import FlagProvider from '@unleash/proxy-client-react';
import {
  Agenda,
  Customers,
  DailyAppointmentsPrint,
  DailyAppointmentsPrintFiche,
  Checkout,
  Treatments,
  StaffMembers,
  Workstations,
  Promotions,
  Costs,
  Manager,
  Company,
  SalesProceeds,
  CompanyCostsAnalysis,
  CompanyProfitabilityAnalysis,
  CompanyAppointmentsAnalysis,
  CollaboratorsProfitabilityAnalysis,
  CollaboratorsReviewsAndLoyaltyAnalysis,
  CollaboratorsPromoAnalysis,
  StockHandlingAnalysis,
  RotationAnalysis,
  Collaborators,
  CustomerAnalysis,
  CustomerDebtsAnalysis,
  CustomersFrequencyAnalysis,
  Stock,
  Inventory,
  Diary,
  Benchmark,
  SalonAnalysis,
  IncomeStatementAnalysis,
  StaffMemberAnalysis,
  WarehouseProducts,
  WarehouseProductsV2,
  WarehouseOrders,
  WarehouseTickler,
  WarehouseManufacturers,
  Timetables,
  TimetablesExtraOpenings,
  Account,
  Marketing,
  ManagerCustomers,
  ProvisioningRoute,
  Plans,
  Plan,
  EcommerceOrders,
  Assistant,
  ShippingOptions,
  FiscalDayReportPrint,
  Reviews,
  RemoteHomepage,
  KycOnboardingRefreshPage,
} from 'components/provisioning_next';

import { VENUE_ACTIVE_FEATURE_FLAG, CMS_CONTENT_PAGE, COUNTRY_FEATURE_FLAG, ACL_PERMISSION } from 'models';
import GuestAccessProvider from 'providers/GuestAccessProvider';
import { RegistryProvider, HostContract } from 'host';
import { StoreFeatureFlag } from 'host/StoreFeatureFlag';
import { NetworkStatusProvider } from 'providers/NetworkStatusProvider';
import { TooltipProvider } from 'ui-treatwell-pro';
import { SalesForceChat } from '@features/SalesforceChat';

class App extends Component {
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    withScope((scope) => {
      scope.setExtra('componentStack', errorInfo.componentStack);
      captureException(error);
    });
  }

  render(): ReactElement {
    return (
      <RegistryProvider registryURL={ENV.UALA_REMOTES_REGISTRY_URL}>
        <Provider store={store}>
          <FlagProvider config={UnleashConfig}>
            <StoreFeatureFlag />
            <SalesForceChat />
            <GlobalsProvider>
              <ThemeProvider theme={theme}>
                <IntlProvider>
                  <PersistGate loading={null} persistor={persistor}>
                    <ModalsAndStatusProvider>
                      <NetworkStatusProvider>
                        <GuestAccessProvider>
                          <SessionsProvider>
                            <DomainProvider>
                              <VenueProvider>
                                <AcceptanceFormsProvider>
                                  <DataProvider>
                                    <LayoutProvider>
                                      <ConnectedRouter history={history}>
                                        <HotjarProvider>
                                          <ScrollToTopProvider>
                                            <TooltipProvider>
                                              <HostContract />
                                              <Switch>
                                                <ProvisioningRoute
                                                  path="/agenda/:date?/"
                                                  exact
                                                  featureComponent={Agenda}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.AGENDA,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.AGENDA}
                                                  aclPermissions={[ACL_PERMISSION.CALENDAR]}
                                                />
                                                <ProvisioningRoute
                                                  path="/agenda/:date?/:staff_member_id?/:customer_id?/print"
                                                  exact
                                                  featureComponent={DailyAppointmentsPrint}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.AGENDA,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.AGENDA}
                                                  aclPermissions={[ACL_PERMISSION.CALENDAR]}
                                                />
                                                <ProvisioningRoute
                                                  path="/agenda/:date?/:customer_id?/print_fiche"
                                                  exact
                                                  featureComponent={DailyAppointmentsPrintFiche}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.AGENDA,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.AGENDA}
                                                  aclPermissions={[ACL_PERMISSION.CALENDAR]}
                                                />
                                                <ProvisioningRoute
                                                  path="/customers/:id?"
                                                  featureComponent={Customers}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.CUSTOMERS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.AGENDA}
                                                  aclPermissions={[ACL_PERMISSION.CUSTOMERS]}
                                                />
                                                <ProvisioningRoute
                                                  path="/fiscal-day/:id?/print"
                                                  featureComponent={FiscalDayReportPrint}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_CASH_REGISTER,
                                                    upsellingPage: CMS_CONTENT_PAGE.CHECKOUT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.CHECKOUT}
                                                  aclPermissions={[ACL_PERMISSION.CASH_REGISTER]}
                                                />
                                                <ProvisioningRoute
                                                  path="/checkout/:type?/:id?/:view_mode?"
                                                  featureComponent={Checkout}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_CASH_REGISTER,
                                                    upsellingPage: CMS_CONTENT_PAGE.CHECKOUT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.CHECKOUT}
                                                  aclPermissions={[ACL_PERMISSION.CASH_REGISTER]}
                                                />
                                                <ProvisioningRoute
                                                  path="/treatments/packages/:id?"
                                                  featureComponent={Treatments}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.TREATMENTS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.TREATMENTS}
                                                  aclPermissions={[ACL_PERMISSION.SERVICES]}
                                                />
                                                <ProvisioningRoute
                                                  path="/treatments/:id?"
                                                  featureComponent={Treatments}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.TREATMENTS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.TREATMENTS}
                                                  aclPermissions={[ACL_PERMISSION.SERVICES]}
                                                />
                                                <ProvisioningRoute
                                                  path="/staff_members/:id?/:role?"
                                                  featureComponent={StaffMembers}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.STAFF_MEMBERS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.STAFF_MEMBERS}
                                                  aclPermissions={[ACL_PERMISSION.STAFF]}
                                                />
                                                <ProvisioningRoute
                                                  path="/workstations/:id?"
                                                  featureComponent={Workstations}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.WORKSTATIONS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.STAFF_MEMBERS}
                                                  aclPermissions={[ACL_PERMISSION.STAFF]}
                                                />
                                                <ProvisioningRoute
                                                  path="/promotions/:id?/:type(generic_discount|bundle_discount)"
                                                  featureComponent={Promotions}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_BASIC_PROMOTIONS,
                                                    upsellingPage: CMS_CONTENT_PAGE.BASIC_PROMOTIONS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.PROMOTIONS}
                                                  aclPermissions={[ACL_PERMISSION.MARKETING]}
                                                />
                                                <ProvisioningRoute
                                                  path="/promotions/:id?/:type(discount_card|voucher|prepaid_credit|subscription_bundle|loyalty_program)"
                                                  featureComponent={Promotions}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_ADVANCED_PROMOTIONS,
                                                    upsellingPage: CMS_CONTENT_PAGE.ADVANCED_PROMOTIONS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.PROMOTIONS}
                                                  aclPermissions={[ACL_PERMISSION.MARKETING]}
                                                />
                                                <ProvisioningRoute
                                                  path="/promotions/:id?"
                                                  featureComponent={Promotions}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_PROMOTIONS,
                                                    upsellingPage: CMS_CONTENT_PAGE.BASIC_PROMOTIONS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.PROMOTIONS}
                                                  aclPermissions={[ACL_PERMISSION.MARKETING]}
                                                />
                                                <ProvisioningRoute
                                                  path="/timetables/extra-openings/:id?"
                                                  featureComponent={TimetablesExtraOpenings}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.TIMETABLES,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.TIMETABLE}
                                                  aclPermissions={[ACL_PERMISSION.BUSINESS_HOURS]}
                                                />
                                                <ProvisioningRoute
                                                  path="/timetables/extra-closing/:id?"
                                                  featureComponent={TimetablesExtraOpenings}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.TIMETABLES,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.TIMETABLE}
                                                  aclPermissions={[ACL_PERMISSION.BUSINESS_HOURS]}
                                                />
                                                <ProvisioningRoute
                                                  path="/timetables/:day?/:id?"
                                                  featureComponent={Timetables}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA,
                                                    upsellingPage: CMS_CONTENT_PAGE.TIMETABLES,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.TIMETABLE}
                                                  aclPermissions={[ACL_PERMISSION.BUSINESS_HOURS]}
                                                />
                                                <ProvisioningRoute
                                                  path="/costs/:id?"
                                                  featureComponent={Costs}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_EXPENSES,
                                                    upsellingPage: CMS_CONTENT_PAGE.COSTS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.COSTS}
                                                  aclPermissions={[ACL_PERMISSION.EXPENSES]}
                                                />
                                                <ProvisioningRoute
                                                  path="/supplier/:id?"
                                                  featureComponent={WarehouseManufacturers}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_EXPENSES,
                                                    upsellingPage: CMS_CONTENT_PAGE.COSTS,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.COSTS}
                                                  aclPermissions={[ACL_PERMISSION.EXPENSES, ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manufacturer/:id?"
                                                  featureComponent={WarehouseManufacturers}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.COSTS}
                                                  aclPermissions={[ACL_PERMISSION.EXPENSES, ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/trend"
                                                  featureComponent={Manager}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_TREND,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_TREND,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_TREND}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_TREND_ANALYSIS,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/company-report/costs-analysis"
                                                  featureComponent={CompanyCostsAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COMPANY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COMPANY_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/company-report/profitability-analysis"
                                                  featureComponent={CompanyProfitabilityAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COMPANY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COMPANY_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/company-report/appointments-analysis"
                                                  featureComponent={CompanyAppointmentsAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COMPANY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COMPANY_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/company-report/sales-proceeds"
                                                  featureComponent={SalesProceeds}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COMPANY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_SALES_PROCEEDS,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/company-report"
                                                  featureComponent={Company}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COMPANY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COMPANY_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/collaborators-report/profitability-analysis"
                                                  featureComponent={CollaboratorsProfitabilityAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COLLABORATORS_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COLLABORATORS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COLLABORATORS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COLLABORATORS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/collaborators-report/reviews-and-loyalty-analysis"
                                                  featureComponent={CollaboratorsReviewsAndLoyaltyAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COLLABORATORS_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COLLABORATORS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COLLABORATORS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COLLABORATORS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/collaborators-report/promotion-analysis"
                                                  featureComponent={CollaboratorsPromoAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COLLABORATORS_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COLLABORATORS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COLLABORATORS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COLLABORATORS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/collaborators-report"
                                                  featureComponent={Collaborators}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COLLABORATORS_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_COLLABORATORS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_COLLABORATORS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_COLLABORATORS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/customers-report/customer-analysis"
                                                  featureComponent={CustomerAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_CUSTOMER_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_CUSTOMERS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_CUSTOMERS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_CUSTOMERS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/customers-report/customer-frequency-analysis"
                                                  featureComponent={CustomersFrequencyAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_CUSTOMER_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_CUSTOMERS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_CUSTOMERS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_CUSTOMERS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/customers-report/customer-debts-analysis"
                                                  featureComponent={CustomerDebtsAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_CUSTOMER_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_CUSTOMERS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_CUSTOMERS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_CUSTOMERS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/customers-report"
                                                  featureComponent={ManagerCustomers}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_CUSTOMER_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_CUSTOMERS_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_CUSTOMERS_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_CUSTOMERS_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/stock-report/handling-analysis"
                                                  featureComponent={StockHandlingAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_STOCK_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_STOCK_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_STOCK_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_STOCK_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/stock-report/rotation-analysis"
                                                  featureComponent={RotationAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_STOCK_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_STOCK_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_STOCK_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_STOCK_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/stock-report"
                                                  featureComponent={Stock}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_STOCK_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_STOCK_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_STOCK_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_STOCK_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/inventory-report"
                                                  featureComponent={Inventory}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_INVENTORY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_INVENTORY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT}
                                                  aclPermissions={[
                                                    ACL_PERMISSION.MANAGER,
                                                    ACL_PERMISSION.MANAGER_INVENTORY_REPORT,
                                                  ]}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/diary/:brandName"
                                                  featureComponent={Diary}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_INVENTORY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/benchmark/:brandName"
                                                  featureComponent={Benchmark}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_INVENTORY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/brand/:brandName/salon-analysis"
                                                  featureComponent={SalonAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_INVENTORY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/brand/:brandName/staff-members-analysis"
                                                  featureComponent={StaffMemberAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_INVENTORY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT}
                                                />
                                                <ProvisioningRoute
                                                  path="/manager/brand/:brandName/income-statement-analysis"
                                                  featureComponent={IncomeStatementAnalysis}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT,
                                                    upsellingPage: CMS_CONTENT_PAGE.MANAGER_INVENTORY_REPORT,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse_v2/product/:id(\d+)?"
                                                  featureComponent={WarehouseProductsV2}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse/product/:id?"
                                                  featureComponent={WarehouseProducts}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse/sent-order/:id?"
                                                  featureComponent={WarehouseOrders}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse/order-delivery-notes/:id?"
                                                  featureComponent={WarehouseOrders}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse/tickler/:type?/:id?/:item?"
                                                  featureComponent={WarehouseTickler}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse/received-order/:id?"
                                                  featureComponent={EcommerceOrders}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE_ECOMMERCE]}
                                                />
                                                <ProvisioningRoute
                                                  path="/warehouse/shipping-option/:id?"
                                                  featureComponent={ShippingOptions}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE,
                                                    upsellingPage: CMS_CONTENT_PAGE.WAREHOUSE,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.WAREHOUSE}
                                                  aclPermissions={[ACL_PERMISSION.WAREHOUSE_ECOMMERCE]}
                                                />
                                                <Redirect from="/warehouse" to="/warehouse/product" />
                                                <ProvisioningRoute
                                                  path="/account/:section?/:tab?"
                                                  featureComponent={Account}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.PROFILE}
                                                  aclPermissions={[ACL_PERMISSION.ACCOUNT]}
                                                />
                                                <ProvisioningRoute
                                                  path="/marketing"
                                                  featureComponent={Marketing}
                                                  venueActiveFeature={{
                                                    flag: VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_MARKETING,
                                                    upsellingPage: CMS_CONTENT_PAGE.MARKETING,
                                                  }}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.MARKETING}
                                                  aclPermissions={[ACL_PERMISSION.MARKETING]}
                                                />
                                                <ProvisioningRoute
                                                  path="/apps/:scope"
                                                  featureComponent={RemoteHomepage}
                                                />
                                                <ProvisioningRoute
                                                  path="/plans"
                                                  exact
                                                  featureComponent={Plans}
                                                  countryFeatureFlag={COUNTRY_FEATURE_FLAG.SELF_PROVISIONING_ENABLED}
                                                />
                                                <ProvisioningRoute
                                                  path="/plans/:plan(basic|lite|full)"
                                                  featureComponent={Plan}
                                                  countryFeatureFlag={COUNTRY_FEATURE_FLAG.SELF_PROVISIONING_ENABLED}
                                                />
                                                <ProvisioningRoute
                                                  path="/assistant"
                                                  featureComponent={Assistant}
                                                  envFeatureFlag={UALA_FEATURE_FLAG.ASSISTANT}
                                                />
                                                <ProvisioningRoute
                                                  path="/preorder-gama"
                                                  venueActiveFeature={{
                                                    upsellingPage: CMS_CONTENT_PAGE.PREORDER_GAMA,
                                                  }}
                                                />
                                                <ProvisioningRoute
                                                  path="/reviews/:id?"
                                                  featureComponent={Reviews}
                                                  aclPermissions={[ACL_PERMISSION.REVIEWS]}
                                                />
                                                <ProvisioningRoute
                                                  path="/stripe_kyc_onboarding"
                                                  featureComponent={KycOnboardingRefreshPage}
                                                  aclPermissions={[ACL_PERMISSION.ACCOUNT_BILLING]}
                                                />
                                                <Redirect
                                                  from="/"
                                                  to={`/${ENV.UALA_HOMEPAGE || 'agenda'}${
                                                    window.location.search || ''
                                                  }`}
                                                />
                                              </Switch>
                                            </TooltipProvider>
                                          </ScrollToTopProvider>
                                        </HotjarProvider>
                                      </ConnectedRouter>
                                    </LayoutProvider>
                                  </DataProvider>
                                </AcceptanceFormsProvider>
                              </VenueProvider>
                            </DomainProvider>
                          </SessionsProvider>
                        </GuestAccessProvider>
                      </NetworkStatusProvider>
                    </ModalsAndStatusProvider>
                  </PersistGate>
                </IntlProvider>
              </ThemeProvider>
            </GlobalsProvider>
          </FlagProvider>
        </Provider>
      </RegistryProvider>
    );
  }
}

export default App;
